export const CUECHANGE = 'cuechange';
export const DURATIONCHANGE = 'durationchange';
export const ENDED = 'ended';
export const ERROR = 'error';
export const LOADEDMETADATA = 'loadedmetadata';
export const LOADSTART = 'loadstart';
export const PAUSE = 'pause';
export const PLAY = 'play';
export const PLAYING = 'playing';
export const PROGRESS = 'progress';
export const RATECHANGE = 'ratechange';
export const RESIZE = 'resize';
export const SEEKED = 'seeked';
export const SEEKING = 'seeking';
export const TIMEUPDATE = 'timeupdate';
export const VOLUMECHANGE = 'volumechange';
