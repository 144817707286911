
export const vimeo = [
  'https://player.vimeo.com',
  'https://i.vimeocdn.com',
  'https://f.vimeocdn.com',
];

export const youtube = [
  'https://www.youtube-nocookie.com',
  'https://s.ytimg.com',
];

export const wistia = [
  'https://fast.wistia.com',
];
